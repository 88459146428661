"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToggleTextContainer = exports.SelectedIconContainer = exports.IconOption = exports.DropdownToggle = exports.DropdownMenu = exports.Container = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var Container = exports.Container = _styledComponents["default"].div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  width: 100%;\n"])));
var DropdownToggle = exports.DropdownToggle = _styledComponents["default"].div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  margin-bottom: 5px;\n"])));
var SelectedIconContainer = exports.SelectedIconContainer = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 75px;\n  background-color: #f1f4fa;\n  border-radius: 4px;\n"])));
var ToggleTextContainer = exports.ToggleTextContainer = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  width: 90%;\n  align-items: center;\n  justify-content: space-evenly;\n  font-weight: bold;\n  font-size: 19px;\n"])));
var DropdownMenu = exports.DropdownMenu = _styledComponents["default"].div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  top: 100%;\n  z-index: 1000;\n  display: grid;\n  padding: 8px;\n  grid-template-columns: repeat(6, 1fr);\n  gap: 11px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background-color: #fff;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n\n  max-height: 200px;\n  overflow-y: auto;\n\n  ::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 4px;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n  }\n\n  ::-webkit-scrollbar-track {\n    background-color: #f1f1f1;\n  }\n"])));
var IconOption = exports.IconOption = _styledComponents["default"].div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2["default"])(["\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: ", ";\n  border-radius: 4px;\n  background-color: ", ";\n  padding: 10px;\n  transition: all 0.3s ease;\n"])), function (_ref) {
  var selected = _ref.selected;
  return selected ? '2px solid #1976d2' : '2px solid transparent';
}, function (_ref2) {
  var selected = _ref2.selected;
  return selected ? '#e3f2fd' : 'transparent';
});